import React from "react";
import "./App.css";
import gizmoatImage from "./assets/gizmoat.png"; // Add your goat PNG file in the assets folder

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1 className="pixel-font">Gizmoat</h1>
      </header>

      <main className="App-main">
        <img
          src={gizmoatImage}
          alt="Main character Gizmoat"
          className="main-character-image"
        />
        <p className="game-description">
          Gizmoat is an exciting adventure game where you hop around, dodge
          obstacles, and try to achieve the highest score possible. But beware!
          A mysterious cloud is always chasing you. How long can you survive?
        </p>
      </main>

      <footer className="App-footer">
        <a href="/privacy-policy" className="footer-link">
          Privacy Statement
        </a>
        <span> | </span>
        <a href="/terms-and-conditions" className="footer-link">
          Terms and Conditions
        </a>
      </footer>
    </div>
  );
}

export default App;
